import React from "react";

export default function DownloadApp() {
  return (
    <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center">
      <a href="https://apps.apple.com/ca/app/vennchat/id6443394582">
        <div className="btn">App Store</div>
      </a>

      <a href="https://play.google.com/store/apps/details?id=ca.richlink.iment">
        <div className="btn">Google Play</div>
      </a>

      <div>
        <a href="https://vhot-android-apk.s3.us-west-2.amazonaws.com/Vennchat_5.8.0-play-release-5.8.0.apk">
          <div className="btn">Chinese Mobile (Android)</div>
        </a>
        <a href="/notification-instructions">
          <p className="text-center text-white">
            Push notification <br /> settings instructions
          </p>
        </a>
      </div>

      <a href="/chinese-apple-instructions">
        <div className="btn">Chinese Mobile (Apple)</div>
      </a>
    </div>
  );
}
