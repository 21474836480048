import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, useRoutes } from "react-router-dom";
import "./index.css";

import App from "./App";

import AboutIm from "./AboutIm";
import AboutImZh from "./AboutImZh";
import Zh from "./Zh";
import NotificationInstructions from "./NotificationInstructions";
import ChineseAppleInstructions from "./ChineseAppleInstructions";
const Routes = () => {
  let routes = useRoutes([
    {
      path: "/",
      element: <App />,
    },
    { path: "/about", element: <AboutIm /> },
    { path: "/about-zh", element: <AboutImZh /> },
    { path: "/zh", element: <Zh /> },
    {
      path: "/notification-instructions",
      element: <NotificationInstructions />,
    },
    {
      path: "/chinese-apple-instructions",
      element: <ChineseAppleInstructions />,
    },
  ]);
  return routes;
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </React.StrictMode>
);
