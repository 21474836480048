import React from "react";

export default function DownloadApp() {
  return (
    <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center">
      <a href="https://apps.apple.com/ca/app/vennchat/id6443394582">
        <div className="btn">苹果商店</div>
      </a>

      <a href="https://play.google.com/store/apps/details?id=ca.richlink.iment">
        <div className="btn">谷歌商店</div>
      </a>

      <div>
        <a href="https://vhot-android-apk.s3.us-west-2.amazonaws.com/Vennchat_5.8.0-play-release-5.8.0.apk">
          <div className="btn">中国手机（安卓）</div>
        </a>
        <a href="/notification-instructions">
          <p className="text-center text-white">打开推送设置说明</p>
        </a>
      </div>

      <a href="/chinese-apple-instructions">
        <div className="btn">中国手机（苹果）</div>
      </a>
    </div>
  );
}
